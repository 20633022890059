import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"

const CooperationPage = () => (
  <>
    <Helmet title="Сотрудничество"></Helmet>
    <StaticQuery
      query={query}
      render={({
        datoCmsCooperation: {
          bodyNode: {
            childMarkdownRemark: { html },
          },
        },
      }) => (
        <Layout>
          <Container>
            <Inner>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </Inner>
          </Container>
        </Layout>
      )}
    ></StaticQuery>
  </>
)

export default CooperationPage

const query = graphql`
  query {
    datoCmsCooperation {
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const Inner = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 32px;
  margin-bottom: 32px;

  line-height: 140%;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
  }
`
